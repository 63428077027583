.react-grid-layout {
    position: relative;
    transition: height 200ms ease;
    background: none !important;
}
.react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
    box-sizing: border-box;
    cursor: grab;
}
.react-grid-item.cssTransforms {
    transition-property: transform;
}
.react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
    opacity: 0.9;
}

.react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
}
  
.react-grid-item.react-grid-placeholder {
    background: lightgray;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}
  
.react-grid-item > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=");
    background-position: bottom right;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
}

.react-grid-item:not(.react-grid-placeholder) {
    border: 1px solid black;
}
.react-grid-item.static {
    border: 1px dashed lightgray;
    border-radius: .25rem;
}
.react-grid-item .text {
    font-size: 1rem;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    margin: auto;
}
.react-grid-item .minMax {
    font-size: 12px;
}
.react-grid-item .add {
    cursor: pointer;
}

.react-grid-item.view-mode > .react-resizable-handle {
    display: none !important;
}

.react-grid-item.view-mode.react-grid-placeholder:hover, 
.react-grid-item.view-mode:hover {
    background-color: initial !important;
    cursor: initial !important;
    transition: initial !important;
}

.react-grid-item.edit-mode.react-grid-placeholder:hover, 
.react-grid-item.edit-mode:hover {
    cursor: grab !important;
    background-color: #f0f0f0 !important;
}