body {
    margin: 0;
    font-family: 'Henry Sans', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.pointer {
    cursor: pointer;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    width: 0;
    min-width: 0 !important;
}

.react-datepicker-wrapper {
    width: 100%;
}