@media only screen and (max-width: 576px) {
    #dropdownMenuInNavBar.dropdown-menu.show {
        background-color: #d3ecff;
    }

    #outletContainer.container-fluid {
        margin-top: 5.25rem !important;
    }
}

button:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
}

.parent-card,
.login-container {
    border-radius: .25em;
    box-shadow: 0 3px 6px rgb(0 0 0 / 50%);
}

.side-bar {
    background-color: rgba(0, 0, 0, 0.03);
    box-shadow: 0 0 6px rgb(0 0 0 / 50%);
}

.otp-container {
    display: flex;
    text-align: center;
    justify-content: center;
    margin-bottom: 2rem;
}

.otp-input {
    text-align: center;
    width: 1.75rem;
    margin: 0rem .5rem 0rem .5rem;
}

.nav-link-active {
    text-decoration: none;
    font-weight: bold;
    color: black !important;
}

.nav-link-default {
    text-decoration: none;
    color: black !important;
}

.wrapper-nav-link-disabled {
    padding-top: 1rem;
    padding-bottom: 1rem;
    cursor: not-allowed;
}

.nav-link-disabled {
    pointer-events: none;
}

.not-allowed {
    cursor: not-allowed;
}

table.rfm-analysis>tbody>tr {
    border-top: 4px solid white;
    border-bottom: 4px solid white;
}

table#campaig-list-table>tbody>tr:first-child {
    border-top: 4px solid #0d6efd;
}

/* table#campaig-list-table > tbody > tr {
    border-top: 5px solid white;
    border-bottom: 5px solid white;
}

table#campaig-list-table > tbody > tr > td:not(:last-child), */
table.rfm-analysis>tbody>tr>td {
    border-right: 1px dotted lightgray;
}

table.rfm-analysis>thead>tr:not(:first-child)>th {
    border-right: 1px solid lightgray;
}

table.rfm-analysis>thead>tr:nth-child(2)>th:first-child {
    border-bottom: none;
}

/* table.rfm-analysis > thead > tr:nth-child(2) > th:not(:empty){
    border-bottom: none;
} */

/* table.rfm-analysis > thead > tr > th:empty{
    border-bottom: none;
} */

table.rfm-analysis>thead,
tbody {
    font-size: small;
}

.parent-rows {
    background-color: #d4d4d447;
}

.child-rows {
    background-color: #d4d4d4bf;
    border-left: 4px solid #0d6efd;

}

.default-cell {
    padding-top: 1rem;
    padding-bottom: 1rem;
    vertical-align: middle;
    text-align: center;
}

.done-step {
    background-color: #00008B;
}

.calendar-style-overrides {
    min-width: -webkit-fill-available;
    font-size: x-large;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
}

.clock-style-overrides {
    font-size: xx-large;
}

.react-time-picker__wrapper {
    border: none !important;
}

.mobile-img {
    width: 100%;
}

.mobile-content {
    position: absolute;
    top: 3.75rem;
    width: 100%;
    left: 0.15rem;
    height: 90%;
}

.viber-color {
    color: #8F5DB7;
}

.viber-bg-color {
    background: #8F5DB7;
}

.email-content-div,
.sms-content-div,
.viber-content-div {
    background-color: #efefef;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    color: #000;
    margin-top: 1rem;
    margin-right: 1.5rem;
}

.sms-content-div {
    border-top-left-radius: 10px;
}

.email-parent-content-div::-webkit-scrollbar,
.sms-parent-content-div::-webkit-scrollbar,
.viber-parent-content-div::-webkit-scrollbar {
    width: 12px;
}

.email-parent-content-div::-webkit-scrollbar-track,
.sms-parent-content-div::-webkit-scrollbar-track,
.viber-parent-content-div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.email-parent-content-div::-webkit-scrollbar-thumb,
.sms-parent-content-div::-webkit-scrollbar-thumb,
.viber-parent-content-div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.viber-parent-content-div {
    max-height: 34rem;
    overflow-y: overlay;
}

.email-parent-content-div {
    max-height: 35rem;
    overflow-y: overlay;
}

.sms-parent-content-div {
    max-height: 36rem;
    overflow-y: overlay;
}

.tox-promotion {
    visibility: hidden;
}

.react-select__menu {
    z-index: 1000 !important;
}


.iframe-embed-mobile-wrapper {
    height: 34rem;
    overflow: hidden;
}

.iframe-embed-web-wrapper {
    height: 40rem;
    overflow: hidden;
}

/* 
div,p {
    color: #8F5DB7;
} */

.leaflet-container {
    width: 100%;
    height: 100vh;
}

.btn-link,
.custom-textbox-search:focus {
    outline: none !important;
    box-shadow: none !important;
}

.searched-item-pill {
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    margin-right: 0.5rem;
    border: 2px solid #6428b4;
    color: #6428b4;
    font-size: small;
}

div.searched-item-pill:first-child {
    margin-left: .75em;
}

.custom-search-bar-container {
    min-height: 3rem;
}

/* OVERRIDE CUSTOM */
.leaflet-control-container>.leaflet-top.leaflet-left {
    z-index: 800 !important;
}

.side-items-cb-container {
    display: flex;
    flex-wrap: wrap;
}

.side-items-cb-container>div {
    flex: 50%;
}

.text-primary {
    color: '#000F73'
}

#nav-pills-mba.nav-pills .nav-link.active, .nav-pills .show > .nav-link,
#nav-pills-location-anaylsis.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: #fff;
    color: #0d6efd;
    font-weight: bold;
    border-bottom: 2px solid;
}

#nav-pills-mba.nav-pills .nav-link,
#nav-pills-location-anaylsis.nav-pills .nav-link {
    border-radius: 0;
    color: black;
}

#navMenuOffCanvas.offcanvas {
    width: 12em;
}

.rdt_TableCol>.rdt_TableCol_Sortable>div {
    white-space: break-spaces;
}

.rdt_TableHead {
    overflow-y: hidden !important;
}

.metric-padding {
    padding-left: .5rem;
}

.datatable-zIndex {
    z-index: 0;
}

.select-margin {
    margin-right: 4rem;
    margin-bottom: 1rem;
}

.section_Banner {
    width: 500px;
    background-color: #082cfc;
    color: white;
    font-weight: bold;
    letter-spacing: 1px;
    padding: 10px;
    margin: 10px;
}

.dropdown-menu {
    z-index: 9999;
}

.chart_Size {
    width: 100%;
    height: 100%;
}

.sales_zIndex1 {
    z-index: 1;
    position: relative;
}

.sales_zIndex2 {
    z-index: 2;
    position: relative;
}

.chart-banner {
    display: flex;
    width: 100%;
    background-color: #4472c4;
    color: white;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 5px;
    padding: 5px;
    min-height: 3em;
    max-height: 4em;
}

.select-chart {
    margin-left: auto;
    color: black;
    font-weight: normal;
}

.ar-banner {
    display: bottom;
    width: 100%;
    background-color: #4472c4;
    color: white;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 5px;
    padding: 5px;
}

.table-container {
    margin-top: 3em;
    /* Adjust this value to create the desired space below the banner */
}

.text-small {
    font-size: small;
}

.sales_zIndex0 {
    z-index: 0;
    position: relative;
}

#outletContainer.container-fluid {
    margin-top: 6rem;
}

.reactTooltip-overrides {
    z-index: 99999 !important;
    min-width: 12rem !important;
    max-width: 25rem !important;
}

/* .reactTooltip-overrides {
    border: 1px solid #000 !important;
}

.reactTooltip-overrides .reactTooltipArrowOverrides ,
.reactTooltip-overrides.place-top::after,
.reactTooltip-overrides.place-bottom::after {
    border-color:  #000 transparent !important;
}

.reactTooltip-overrides.place-right::after,
.reactTooltip-overrides.place-left::after {
    border-color:  transparent #000 !important;
} */

.button-style {
    width: 7rem;
    height: 2.5rem;

}

.ReactModal__Overlay {
    position: fixed !important;
    top: 5rem !important;
}

.ReactModal__Content {
    bottom: 10% !important;
    left: 20%;
    right: 15% !important;
}

.tree-view {
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 300px;
    overflow-y: auto;
}

.search_input {
    border: 1px solid #007bff;
    padding: 5px;
    margin-bottom: 10px;
}

.onClick_input:after {
    border: none !important;
}

.TreeViewHeight {
    height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
}

#hl.form-control:focus {
    border-color: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.nav-link {
    cursor: pointer;
}

.nav-link.active {
    color: black;
    font-weight: bold;
}

.nav-link:not(.active) {
    color: #808080;
    font-weight: normal;
}

.bold {
    font-weight: bold;
}

.normal {
    font-weight: normal;
}

.TreeView_margin {
    margin-left: 20px;
}

.pointer-cursor {
    cursor: pointer !important;
}

.TreeView_indent {
    padding: 5px;
    cursor: pointer;
    display: flex;
}

.TreeView_flex {
    display: flex;
    flex-grow: 1;
    text-align: left;
}

.TreeView_indent:hover {
    background-color: lightgray;
}

.highlighted,
.highlighted:hover {
    background-color: #d3ecff;
}

.slider-overrides {
    width: 15rem !important;
}

/* table#SalesPerformancePerGroupAverageTable {
    border: 1px solid lightgray;
}

table#SalesPerformancePerGroupAverageTable > thead {
    background-color: #4472c4;
    font-size: 1rem;
}

table#SalesPerformancePerGroupAverageTable > thead > tr:not(:first-child) > th:not(:first-child) {
    font-weight: 500;
}

table#SalesPerformancePerGroupAverageTable > tbody > tr > td {
    font-size: .95rem;
}

table#SalesPerformancePerGroupAverageTable > thead > tr > th:not(:last-child) {
    border-right: 1px solid white;
}

tbody {
    border-bottom: 1px solid lightgray;
}
  
th {
    border-bottom: 1px solid lightgray;
    border-right: 1px solid lightgray;
    padding: 2px 4px;
} */

.sales-header-section {
    background-color: #4472c4;
}


i#diy_db_add_container.bi.bi-plus-square-dotted {
    display: inline-block;
    position: relative;
}

i#diy_db_add_container.bi.bi-plus-square-dotted::after {
    content: 'Add Container';
    display: inline-block;
    position: absolute;
    color: lightgray;
    top:70%;
    left:20%;
    font-size: 1rem;
}
.bg-smblue {
    /* SM Royal Blue */
    background-color: #000f73;
}

.text-smblue {
    /* SM Royal Blue */
    color: #000f73;
}

.overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
  
.tooltip-zIndex {
    z-index: 9999;
}


.chart-selector {
    max-height: 80%;
    overflow-y: scroll;
}
.chart-selector .chart-card {
    cursor: pointer;
}

.chart-selector .chart-card:hover .card-body {
    background-color: lightgray !important;
}

.chart-selector .chart-card.active .card-body {
    background-color: aliceblue;
}
.img-fluid{
    height: 10rem !important;
}


@media screen and (max-width: 576px) {
    /*
     small screen: allows the user to scroll through all content as one single scroll bar
     big screens separates the scroll bars between the two columns/panes
      */
    #edit-chart-modal {
        overflow-y: scroll !important;
    }

    #edit-chart-modal .chart-detail-pane {
        max-height: initial !important;
        border-bottom: 1px solid lightgray;
    }
    #edit-chart-modal .edit-chart-row-container {
        height: initial !important;
    }

    #edit-chart-modal .edit-chart-save-container {
        position: relative !important;
    }
}

#edit-chart-modal .edit-chart-row-container {
    height: 100%;
}
#edit-chart-modal .chart-detail-pane {
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}
#edit-chart-modal .edit-chart-save-container {
    position: absolute;
    bottom: 0;
    right: 0;
    text-align: right;
}

#edit-chart-modal .chart-preview-pane {
    overflow-y: auto;
    overflow-x: hidden;
}

#edit-chart-modal .chart-preview-pane {
    max-height: 475px;
}
#edit-chart-modal .chart-preview-pane.chart-type-table {
    max-height: 100%;
}



/* CHART LIST */
.chart-preview-img {
    max-height: 16rem;
    object-fit: cover;
    object-position: top;
}
.chart-preview-img:hover {
    object-fit: contain;
}

.required-field::after {
    content: ' *';
    color: red;
}

.ribbon {
    width: 100px;
    height: 100px;
    overflow: hidden;
    position: absolute;
}

.ribbon::before,
.ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #2980b9;
}

.ribbon span {
    position: absolute;
    display: block;
    width: 315px;
    background-color: #1976d2;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
    font-size: xx-small;
}

.ribbon-top-right {
    top: 0;
    right: 0;
  }
  .ribbon-top-right::before,
  .ribbon-top-right::after {
    border-top-color: transparent;
    border-right-color: transparent;
  }
  .ribbon-top-right::before {
    top: 0;
    left: 0;
  }
  .ribbon-top-right::after {
    bottom: 0;
    right: 0;
  }
  .ribbon-top-right span {
    left: -87px;
    top: 25px;
    transform: rotate(45deg);
  }