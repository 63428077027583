.custom-tooltip {
    font-size: 10px;
    padding: 4px;
    background-color: transparent !important;
    border: none;
    box-shadow: none;
  }
  

  .legend-container {
    /* padding: 5px; */
    padding-left: 10px;
    padding-right: 10px;
    justify-content: space-between;
  }
  
  .legend-grid {
    display: grid;
    grid-template-columns: auto auto 1fr;
    gap: 8px;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
  }
  
  .circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 8px;
  }
  
  .text {
    margin-left: 8px;
  }
  
  .legend-item:nth-child(5) {
    justify-self: end;
  }

  .gradient-map {
    margin-top: 5px;
    width: 365px;
    height: 15px;
    background: linear-gradient(to right, #A0FF9A, #033800);
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .legend-labels {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
  }
  
  
  